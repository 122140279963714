import { useEffect, useRef, createContext} from "react"
import './ServicesSection.scss'
import servicesImage from '../../images/servicesImage.png'


var servicesRef = createContext()

const ServicesSection = () => {
  const serviceImageRef = useRef()
  servicesRef = useRef()

  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting)
          entry.target.classList.add("fadeIn")
      })
    }, {
      threshold: 0.5
    })
    observer.observe(serviceImageRef.current)
  }, [])

  return (    
    <section className="serviceSection" ref={servicesRef}>
      <div className="serviceText">
        <div className="motto">
          Services
        </div>
        <div className="mottoSubtitle">
          We have strong offshore development and maintenance capabilities and equally strong onsite implementation capabilities. This robust offshore development methodology allows us to start on new projects swiftly and proceed smoothly to deliver high quality results. 
          <br /><br />
          The main areas that we focus on are:
          <ul>
            <li>- 2D Game Development</li>
            <li>- 3D Game Development</li>
            <li>- Application Development</li>
          </ul> 
          Categories:
          All Casual and HyperCasual Categories
        </div>
      </div>
      <div className="imageSection">
        <img 
          src={servicesImage} 
          className='serviceImage'
          ref={serviceImageRef} 
          alt="Services"
          width={515} height={515}/>
      </div>
    </section>
  )
}

export default ServicesSection
export {servicesRef}
