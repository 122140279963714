// import { useEffect, useRef } from 'react'
// import adsImage from '../../images/welcomeImage.png'
import './AdsPage.scss'
// import data from '../../constants/data'

const AdsPageSection = () => {

  return (    
    <section className="welcomeSection">
      <div className="welcomeText">
        <div className="motto">
          app-ads:
        </div>
        <div className='body'>

          
unity.com, 4769839, DIRECT, 96cabb5fbdde37a7<br />
inmobi.com, 4028cb972f4a0213012f525c3fbb010a, DIRECT, 83e75a7ae333ca9d<br />
inmobi.com, 89eea72626a14f89b92477e06a2f9f48, DIRECT, 83e75a7ae333ca9d<br />
inmobi.com, 22e5354e453f49348325184e25464adb, RESELLER, 83e75a7ae333ca9d<br />
facebook.com, 745468068858175, DIRECT, c3e20eee3f780d68<br />
facebook.com, 251447367568847, DIRECT, c3e20eee3f780d68<br />
eskimi.com, 2020000030, RESELLER<br />
freewheel.tv, 1585681, RESELLER<br />
loopme.com, 11414, RESELLER, 6c8d5f95897a5a3b<br />
opera.com, pub5925993551616, RESELLER, 55a0c5fd61378de3<br />
google.com, pub-6643580892060154, DIRECT, f08c47fec0942fa0<br />
thebrave.io, 1234568, RESELLER, c25b2154543746ac<br />
rubiconproject.com, 9753, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 20744, RESELLER, 0bfd66d529a55807<br />
google.com, pub-4489546446191995, DIRECT, f08c47fec0942fa0<br />
pubmatic.com, 159035, RESELLER, 5d62403b186f2ace<br />
verve.com, 5897, RESELLER, 0c8f5958fc2d6270<br />
conversantmedia.com, 100358, RESELLER, 03113cd04947736d<br />
ssp.e-volution.ai, AJxF6R378a9M6CaTvK, RESELLER<br />
spotx.tv, 82068, RESELLER, 7842df1d2fe2db34<br />
google.com, pub-9378833380768142, DIRECT, f08c47fec0942fa0<br />
improvedigital.com, 2181, RESELLER<br />
Peak226.com, 12900, RESELLER<br />
pubmatic.com, 160113, RESELLER, 5d62403b186f2ace<br />
smartadserver.com, 4071, RESELLER<br />
spotxchange.com, 82068, RESELLER, 7842df1d2fe2db34<br />
smartadserver.com, 4539, RESELLER, 060d053dcf45cbf3<br />
indexexchange.com, 191332, RESELLER, 50b1c356f2c5c8fc<br />
conversantmedia.com, 100432, RESELLER<br />
bidmachine.io, 35, RESELLER<br />
advertising.com, 28246, RESELLER<br />
axonix.com, 57716, RESELLER<br />
gamaigroup.com, 320201, RESELLER<br />
pubmatic.com, 155975, RESELLER, 5d62403b186f2ace<br />
smartadserver.com, 4016, RESELLER<br />
openx.com, 541056459, RESELLER, 6a698e2ec38604c6<br />
advlion.com, 3083, RESELLER<br />
appnexus.com, 9684, RESELLER, f5ab79cb980f11d1<br />
rhythmone.com, 2464975885, RESELLER, a670c89d4a324e47<br />
Blis.com, 33, RESELLER, 61453ae19a4b73f4<br />
contextweb.com, 562499, RESELLER, 89ff185a4c4e857c<br />
video.unrulymedia.com, 76764238648089251, RESELLER<br />
bksn.se, 9d7a47c4-cfb9-4828-a4fb-7c328d69464b, RESELLER<br />
opera.com, pub5865193350528, RESELLER, 55a0c5fd61378de3<br />
indexexchange.com, 194730, RESELLER<br />
algorix.co, 54190, RESELLER, 5b394c12fea27a1d<br />
iqzone.com, IQ87, RESELLER<br />
video.unrulymedia.com, 3855144660485329163, RESELLER<br />
bidmachine.io, 59, RESELLER<br />
admanmedia.com, 990, RESELLER<br />
advlion.com, 3108, RESELLER<br />
outbrain.com, 00bba279fec6daa01a0cb6fdccb023f0d5, RESELLER<br />
Contextweb.com, 562950, RESELLER, 89ff185a4c4e857c<br />
yahoo.com, 58935, RESELLER, e1a5b5b6e3255540<br />
webeyemob.com, 70130, RESELLER<br />
aralego.com, par-8A2AD843B76A2B93CB24D4B7D694B67E, RESELLER<br />
smartadserver.com, 4073, RESELLER<br />
tpmn.io, 415, RESELLER<br />
Beapup.com, 544587, RESELLER<br />
verve.com, 15503, RESELLER, 0c8f5958fc2d6270<br />
sonobi.com, eaec54c63f, RESELLER, d1a215d9eb5aee9e<br />
improvedigital.com, 1532, RESELLER<br />
mars.media, 1010450, RESELLER, 8624339f102fb076<br />
prequel.tv, 518, RESELLER<br />
mintegral.com, 10003, RESELLER<br />
themediagrid.com, FH3TKJ, RESELLER, 35d5010d7789b49d<br />
Pubmatic.com, 157704, RESELLER, 5d62403b186f2ace<br />
appnexus.com, 12878, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 12501, RESELLER, f5ab79cb980f11d1<br />
smartadserver.com, 4190, RESELLER<br />
ucfunnel.com, par-8A2AD843B76A2B93CB24D4B7D694B67E, RESELLER<br />
growintech.co, 102275, RESELLER<br />
mobismarter.com, 3294, RESELLER<br />
bidmachine.io, 138, RESELLER<br />
pubmatic.com, 156931, RESELLER, 5d62403b186f2ace<br />
smartyads.com, 100025, RESELLER, fd2bde0ff2e62c5d<br />
lemmatechnologies.com, 89, RESELLER, 7829010c5bebd1fb<br />
sharethrough.com, K5WDGAsP, RESELLER, d53b998a7bd4ecd2<br />
Xapads.com, 106073, RESELLER<br />
loopme.com, 11544, RESELLER, 6c8d5f95897a5a3b<br />
openx.com, 539214772, RESELLER, 6a698e2ec38604c6<br />
pubmatic.com, 157559, RESELLER, 5d62403b186f2ace<br />
adiiix.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER<br />
triplelift.com, 13530, RESELLER, 6c33edb13117fd86<br />
pubnative.net, 1006951, RESELLER, d641df8625486a7b<br />
openx.com, 540298543, RESELLER, 6a698e2ec38604c6<br />
smartadserver.com, 3232, RESELLER<br />
video.unrulymedia.com, 8130871856725242222, RESELLER<br />
onetag.com, 59aa7be4921bac8, RESELLER<br />
triplelift.com, 11656, RESELLER, 6c33edb13117fd86<br />
pubmatic.com, 161056, RESELLER, 5d62403b186f2ace<br />
appnexus.com, 12061, RESELLER, f5ab79cb980f11d1<br />
contextweb.com, 562569, RESELLER, 89ff185a4c4e857c<br />
google.com, pub-3990748024667386, RESELLER, f08c47fec0942fa0<br />
improvedigital.com, 2174, RESELLER<br />
engagebdr.com, 10423, RESELLER<br />
acexchange.co.kr, 1562884725, RESELLER<br />
ucfunnel.com, par-9A2627866DA4BBE4F4AA4A67A3DDE686, RESELLER<br />
9dotsmedia.com, 122262, RESELLER, 45ff185b4c4e857d<br />
instreamatic.com, 137, RESELLER<br />
rubiconproject.com, 17328, RESELLER, 0bfd66d529a55807<br />
loopme.com, 11322, RESELLER, 6c8d5f95897a5a3b<br />
advlion.com, 584, RESELLER<br />
appnexus.com, 7265, RESELLER, f5ab79cb980f11d1<br />
waardex.com, 99236, RESELLER<br />
adform.com, 2995, RESELLER, 9f5210a2f0999e32<br />
rubiconproject.com, 16114, RESELLER, 0bfd66d529a55807<br />
pubmatic.com, 158060, RESELLER, 5d62403b186f2ace<br />
algorix.co, 60097, RESELLER, 5b394c12fea27a1d<br />
yandex.com, 95746987, RESELLER<br />
appnexus.com, 13227, RESELLER<br />
opera.com, pub6871903319744, RESELLER, 55a0c5fd61378de3<br />
webeyemob.com, 70104, RESELLER<br />
rhythmone.com, 2564526802, RESELLER, a670c89d4a324e47<br />
rubiconproject.com, 12266, RESELLER, 0bfd66d529a55807<br />
Pubmatic.com, 161771, RESELLER, 5d62403b186f2ace<br />
video.unrulymedia.com, 188404962, RESELLER<br />
rubiconproject.com, 20050, RESELLER, 0bfd66d529a55807<br />
engagebdr.com, 10252, RESELLER<br />
openx.com, 540838151, RESELLER, 6a698e2ec38604c6<br />
aralego.com, par-9A2627866DA4BBE4F4AA4A67A3DDE686, RESELLER<br />
rubiconproject.com, 25064, RESELLER, 0bfd66d529a55807<br />
rhythmone.com, 4173858586, RESELLER, a670c89d4a324e47<br />
meitu.com, 698, RESELLER<br />
rhebus.works, 7597018658, RESELLER<br />
loopme.com, 9724, RESELLER, 6c8d5f95897a5a3b<br />
appnexus.com, 7597, RESELLER, f5ab79cb980f11d1<br />
lunamedia.io, b82743ba2ddf4bcaab3d1d4f91b1330e, RESELLER, 524ecb396915caaf<br />
smartadserver.com, 4074, RESELLER<br />
pubmatic.com, 159950, RESELLER, 5d62403b186f2ace<br />
Peak226.com, 12700, RESELLER<br />
algorix.co, 60095, RESELLER, 5b394c12fea27a1d<br />
engagebdr.com, 10294, RESELLER<br />
video.unrulymedia.com, 8167205979129043832, RESELLER<br />
start.io, inm, RESELLER<br />
aralego.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER<br />
advlion.com, 3054, RESELLER<br />
rubiconproject.com, 23822, RESELLER, Obfd66d529a55807<br />
algorix.co, 60367, RESELLER, 5b394c12fea27a1d<br />
pubmatic.com, 160145, RESELLER, 5d62403b186f2ace<br />
criteo.com, B-057955, RESELLER, 9fac4a4a87c2a44f<br />
sharethrough.com, a5XFp5lj, RESELLER, d53b998a7bd4ecd2<br />
visiblemeasures.com, 1020, RESELLER<br />
algorix.co, 60197, RESELLER, 5b394c12fea27a1d<br />
pubmatic.com, 158772, RESELLER, 5d62403b186f2ace<br />
video.unrulymedia.com, 123476257, RESELLER<br />
xandr.com, 13799, RESELLER<br />
loopme.com, 11421, RESELLER, 6c8d5f95897a5a3b<br />
targetspot.com, 312, RESELLER, feb28ed826dcf532<br />
lkqd.net, 626, RESELLER, 59c49fa9598a0117<br />
admixer.co.kr, 1511, RESELLER<br />
contextweb.com, 562329, RESELLER, 89ff185a4c4e857c<br />
pubmatic.com, 161372, RESELLER, 5d62403b186f2ace<br />
consumable.com, 2001470, RESELLER, aefcd3d2f45b5070<br />
freewheel.tv, 1585652, RESELLER<br />
video.unrulymedia.com, 1244444110, RESELLER<br />
smartclip.net, 14275, RESELLER<br />
rubiconproject.com, 25060, RESELLER, 0bfd66d529a55807<br />
axonix.com, 55724, RESELLER<br />
rubiconproject.com, 16418, RESELLER, 0bfd66d529a55807<br />
xandr.com, 13167, RESELLER<br />
openx.com, 537149888, RESELLER, 6a698e2ec38604c6<br />
smartclip.net, 12358, RESELLER<br />
rubiconproject.com, 11726, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 16928, RESELLER, 0bfd66d529a55807<br />
smartadserver.com, 4012, RESELLER<br />
aralego.com, par-D2346AAB7ABD36B4CDD7BBD264BA92E2, RESELLER<br />
rubiconproject.com, 24362, RESELLER, 0bfd66d529a55807<br />
Unruly.co, 2979066401945419350, RESELLER<br />
adcolony.com, 197af3936679d34e, RESELLER, 1ad675c9de6b5176<br />
video.unrulymedia.com, 2464975885, RESELLER<br />
smaato.com, 1100051178, RESELLER, 07bcf65f187117b4<br />
triplelift.com, 12240, RESELLER, 6c33edb13117fd86<br />
adelement.com, 30020, RESELLER<br />
openx.com, 540679900, RESELLER, 6a698e2ec38604c6<br />
appnexus.com, 8178, RESELLER, f5ab79cb980f11d1<br />
rhythmone.com, 188404962, RESELLER, a670c89d4a324e47<br />
sonobi.com, cc3858f35e, RESELLER, d1a215d9eb5aee9e<br />
eskimi.com, 2020000011, RESELLER<br />
pubnative.net, 1006576, RESELLER, d641df8625486a7b<br />
yahoo.com, 59961, RESELLER, e1a5b5b6e3255540<br />
pubmatic.com, 163120, RESELLER, 5d62403b186f2ace<br />
ucfunnel.com, par-AA7B236BA32DD484C838E249362437B8, RESELLER<br />
Rubiconproject.com, 15278, RESELLER, 0bfd66d529a55807<br />
conversantmedia.com, 40881, RESELLER, 03113cd04947736d<br />
video.unrulymedia.com, 564934834, RESELLER<br />
Se7en.es, 212430, RESELLER, 064bc410192443d8<br />
pubmatic.com, 157097, RESELLER, 5d62403b186f2ace<br />
rubiconproject.com, 24448, RESELLER, 0bfd66d529a55807<br />
sharethrough.com, UPD4WJlm, RESELLER, d53b998a7bd4ecd2<br />
algorix.co, 60216, RESELLER, 5b394c12fea27a1d<br />
indexexchange.com, 184738, RESELLER<br />
pubmatic.com, 161136, RESELLER, 5d62403b186f2ace<br />
meitu.com, 699, RESELLER<br />
video.unrulymedia.com, 115018688080045007, RESELLER<br />
adcolony.com, 0a0f72cd66122f31, RESELLER, 1ad675c9de6b5176<br />
adcolony.com, 801e49d1be83b5f9, RESELLER, 1ad675c9de6b5176<br />
adelement.com, 30167, RESELLER<br />
admanmedia.com, 613, RESELLER<br />
admixer.net, 4ca083cd-412f-49fe-b832-5b66ee490d9a, RESELLER<br />
admixplay.com, 2012, RESELLER<br />
adview.com, 48045325, RESELLER, 1b2cc038a11ea319<br />
advlion.com, 3054, RESELLER<br />
advlion.com, 3083, RESELLER<br />
advlion.com, 3108, RESELLER<br />
advlion.com, 5140, RESELLER<br />
algorix.co, 54616, RESELLER, 5b394c12fea27a1d<br />
algorix.co, 60016, RESELLER, 5b394c12fea27a1d<br />
appads.in, 106246, RESELLER<br />
appads.in, 107606, RESELLER<br />
appnexus.com, 10617, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 11826, RESELLER<br />
appnexus.com, 12976, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 13227, RESELLER<br />
appnexus.com, 13293, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 13297, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 2764, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 7353, RESELLER, f5ab79cb980f11d1<br />
appnexus.com, 9393, RESELLER, f5ab79cb980f11d1<br />
bidease.com, exchange-20, RESELLER<br />
bidmachine.io, 113, RESELLER<br />
bidmachine.io, 114, RESELLER<br />
bidmachine.io, 67, RESELLER<br />
contextweb.com, 562499, RESELLER, 89ff185a4c4e857c<br />
contextweb.com, 562791, RESELLER, 89ff185a4c4e857c<br />
contextweb.com, 562842, RESELLER, 89ff185a4c4e857c<br />
contextweb.com, 562944, RESELLER, 89ff185a4c4e857c<br />
criteo.com, B-057601, RESELLER, 9fac4a4a87c2a44f<br />
criteo.com, B-057955, RESELLER, 9fac4a4a87c2a44f<br />
criteo.com, B-063105, RESELLER, 9fac4a4a87c2a44f<br />
exchange.admazing.co, AM3, RESELLER<br />
indexexchange.com, 186081, RESELLER, 50b1c356f2c5c8fc<br />
indexexchange.com, 186684, RESELLER, 50b1c356f2c5c8fc<br />
indexexchange.com, 194080, RESELLER, 50b1c356f2c5c8fc<br />
indexexchange.com, 194730, RESELLER, 50b1c356f2c5c8fc<br />
indexexchange.com, 198417, RESELLER, 50b1c356f2c5c8fc<br />
indexexchange.com, 199195, RESELLER, 50b1c356f2c5c8fc<br />
inmobi.com, 062029933580429f9920bad476d8d70a, RESELLER, 83e75a7ae333ca9d<br />
inmobi.com, 3a4f7da341dd490cbb7dde02b126275e, RESELLER, 83e75a7ae333ca9d<br />
inmobi.com, 61d733c3779d43e590c51c8bc078e10c, RESELLER, 83e75a7ae333ca9d<br />
kidoz.net, 19692013, RESELLER<br />
lemmatechnologies.com, 362, RESELLER, 7829010c5bebd1fb<br />
liftoff.io, 7f6945815e6, RESELLER<br />
loopme.com, 11414, RESELLER, 6c8d5f95897a5a3b<br />
loopme.com, 9621, RESELLER, 6c8d5f95897a5a3b<br />
meitu.com, 282, RESELLER<br />
meitu.com, 699, RESELLER<br />
mintegral.com, 10038, RESELLER<br />
openx.com, 540679900, RESELLER, 6a698e2ec38604c6<br />
openx.com, 540838151, RESELLER, 6a698e2ec38604c6<br />
openx.com, 540866936, RESELLER, 6a698e2ec38604c6<br />
openx.com, 540871654, RESELLER, 6a698e2ec38604c6<br />
openx.com, 541031350, RESELLER, 6a698e2ec38604c6<br />
opera.com, pub6794361378752, RESELLER, 55a0c5fd61378de3<br />
pokkt.com, 6246, RESELLER, c45702d9311e25fd<br />
pokkt.com, 7606, RESELLER, c45702d9311e25fd<br />
pubmatic.com, 156494, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 156520, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 157384, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 157800, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 158060, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 158118, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 158481, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 158565, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 159501, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 159906, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 160113, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 160456, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 160536, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 160846, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 160974, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 161018, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 161136, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 161344, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 161372, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 161853, RESELLER, 5d62403b186f2ace<br />
pubmatic.com, 162223, RESELLER, 5d62403b186f2ace<br />
Pubmatic.com, 162882, RESELLER, 5d62403b186f2ace<br />
pubnative.net, 1003601, RESELLER, d641df8625486a7b<br />
pubnative.net, 1006955, RESELLER, d641df8625486a7b<br />
pubnative.net, 1007040, RESELLER, d641df8625486a7b<br />
pubnative.net, 1007063, RESELLER, d641df8625486a7b<br />
reforge.in, 107, RESELLER<br />
reforge.in, 353, RESELLER<br />
rhythmone.com, 3383599585, RESELLER, a670c89d4a324e47<br />
rhythmone.com, 3630748062, RESELLER, a670c89d4a324e47<br />
rubiconproject.com, 14334, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 17608, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 18364, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 20014, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 20050, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 20086, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 20744, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 21526, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 24362, RESELLER, 0bfd66d529a55807<br />
rubiconproject.com, 24526, RESELLER, 0bfd66d529a55807<br />
spotx.tv, 117872, RESELLER, 7842df1d2fe2db34<br />
spotx.tv, 149886, RESELLER, 7842df1d2fe2db34<br />
spotx.tv, 71426, RESELLER, 7842df1d2fe2db34<br />
spotxchange.com, 117872, RESELLER, 7842df1d2fe2db34<br />
spotxchange.com, 149886, RESELLER, 7842df1d2fe2db34<br />
spotxchange.com, 71426, RESELLER, 7842df1d2fe2db34<br />
thebrave.io, 1234594, RESELLER, c25b2154543746ac<br />
thebrave.io, 1234634, RESELLER, c25b2154543746ac<br />
thebrave.io, 9840732, RESELLER, c25b2154543746ac<br />
themediagrid.com, SWH94X, RESELLER, 35d5010d7789b49d<br />
unity.com, 4769839, DIRECT, 96cabb5fbdde37a7<br />
velismedia.com, 1137, RESELLER<br />
velismedia.com, 725, RESELLER<br />
video.unrulymedia.com, 123476257, RESELLER<br />
video.unrulymedia.com, 170071695, RESELLER<br />
video.unrulymedia.com, 3383599585, RESELLER, a670c89d4a324e47<br />
video.unrulymedia.com, 3630748062, RESELLER, a670c89d4a324e47<br />
videoheroes.tv, 212499, RESELLER, 064bc410192443d8<br />
webeyemob.com, 70097, RESELLER<br />
webeyemob.com, 70104, RESELLER<br />
xandr.com, 13167, RESELLER, f5ab79cb980f11d1<br />
xandr.com, 13297, RESELLER, f5ab79cb980f11d1<br />
xandr.com, 13799, RESELLER<br />
xandr.com, 7353, RESELLER, f5ab79cb980f11d1<br />
xandr.com, 8804, RESELLER, f5ab79cb980f11d1<br />
Xapads.com, 117219, RESELLER<br />
yandex.com, 40427798, RESELLER<br />
yandex.com, 95746987, RESELLER<br />
yandex.com, 97539269, RESELLER<br />
yeahmobi.com, 5135195, RESELLER<br />

        </div>
      </div>
    </section>
  )
}

export default AdsPageSection
